.flip-number {
  font-size: 1.2em;
  font-family: 'Helvetica', sans-serif;
  perspective: 1000px;
  display: inline-block;
  transition: transform 0.6s cubic-bezier(0.36, 0.07, 0.19, 0.97);
  transform-style: preserve-3d;
  background: transparent; /* Make background transparent or match to your div's background */
  margin: 0; /* Remove any default margin */
  padding: 0; /* Remove any default padding */
}

.flip-number {
  animation: flip ease-in-out 0.6s;
}

@keyframes flip {
  0% { transform: rotateX(0deg); }
  100% { transform: rotateX(360deg); }
}
