.dashboard {
    display: flex;
    justify-content: space-around; /* Distributes space evenly around each item */
    align-items: center; /* Aligns items vertically in the center */
    flex-wrap: wrap; /* Allows items to wrap to next line on smaller screens */
    padding: 20px;
}

.dashboard-item {
    text-align: center;
    width: 8em; /* Adjust size as needed */
    height:8em; /* Keep the same as width for perfect circles */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: #fff;
    border: 2px solid #ccc;
    margin: 10px; /* Provides space between circles */
    font-size: 0.8em;
}


.dashboard-item h3 {
  margin-bottom: 10px;
  font-size: 1.2em;
}

.flip-number {
  font-size: 1.7em;
  font-weight: bold;
}

/* Responsive adjustments for smaller screens */
@media (max-width: 768px) {
  .dashboard-item {
    width: 150px;  /* Smaller width for mobile */
    height: 150px; /* Smaller height for mobile */
    font-size: 1em; /* Adjusted font size for smaller circles */
  }
}
